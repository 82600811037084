<template>
  <router-view />
</template>
<script>
import Theme from "@/utils/theme";
export default {
  mounted() {
    Theme.change("#15317F");
    //  Theme.change('#000000')

  },
};
</script>
<style>
.roadBox{
  width:100px;
  height: 60px;
  background: pink;
  border-radius: 10px;
}
.roadBox .title{
  /* width: 100%; */
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border-radius: 10px 10px 0 0;

  background-color: #ff0000;
}
.roadBox .titleTwo{
  /* width: 100%; */
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border-radius: 10px 10px 0 0;

  background-color: #f4f808;
}
.roadBox .content{
  /* width: 100%; */
  line-height: 35px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border-radius:0 0 10px 10px;

  background-color: #00A045;
}

</style>