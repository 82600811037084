// 小时分秒补零
const addZeor = function(num) {
  return num < 10 ? '0' + num : num
}

// 时间戳转日期
const timeTodate = function(timestamp, format) {
  var formateArr = ['Y', 'm', 'd', 'H', 'i', 's']
  var returnArr = []
  
  if (parseInt(timestamp) === 0 || isNaN(parseInt(timestamp))) {
    return '-'
  }

timestamp = timestamp > 1000000000 ? timestamp : timestamp * 1000
  var date = new Date(parseInt(timestamp))

  returnArr.push(date.getFullYear())

  var month = date.getMonth() + 1
  month = month < 10 ? '0' + month : month
  returnArr.push(month)

  var day = date.getDate()
  day = day < 10 ? '0' + day : day
  returnArr.push(day)

  var hours = date.getHours()
  hours = hours < 10 ? '0' + hours : hours
  returnArr.push(hours)

  var minute = date.getMinutes()
  minute = minute < 10 ? '0' + minute : minute
  returnArr.push(minute)

  var second = date.getSeconds()
  second = second < 10 ? '0' + second : second
  returnArr.push(second)

  returnArr.forEach(function(v, i) {
    format = format.replace(formateArr[i], returnArr[i])
  })
  return format
}

//距离当前时间过去多久，多久前发布，传入时间戳
const timeToHour = function(timestamp) {
  let times = Date.now() - timestamp
  let str = ''
  if (times > 864000) {
    str = timeTodate(timestamp, 'Y-m-d')
  } else if (times > 86400) {
    str = times % 86400 + '天前'
  } else if (times > 3600) {
    str = times % 3600 + '小时前'
  } else if (times > 60) {
    str = times % 60 + '分钟前'
  } else {
    str = '刚刚'
  }

  return str
}

//手机号加密
const mobileEncryption = function(mobile) {
  let mobileStr = mobile.toString()
  return mobileStr.substring(0, 3) + '****' + mobileStr.substring(8, 11)
}

// 分转元并且小数保留2位
const moneyFormatY = function(num, isdecimal) {
  var y = (num / 100).toFixed(2)
  if (isdecimal) {
    var index = y.lastIndexOf('.')
    var zero = '00000'
    if (index == -1) {
      return y + '.00'
    } else {
      return y + zero.substring(0, 3 - y.length + index)
    }
  }
  return Number(y)
}


module.exports = {
  mobileEncryption,
  timeToHour,
  timeTodate,
  addZeor,
  moneyFormatY,
}
